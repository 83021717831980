import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import { Stack, useTheme } from "@mui/material";
import { FacilityDetailsCollapse } from "@src/appV2/Facilities/components/FacilityDetailsCollapse";
import { type StreaksSettings } from "@src/appV2/Streaks/api/useGetStreaksSettings";
import { SUPPORT_LINKS } from "@src/appV2/Streaks/constants";

import { type Streak } from "../types";
import { formatPayBoostPercentage, getActiveStreakByDate } from "../utils";

interface FacilityStreakInfoCardProps {
  streaks: Streak[];
  streaksSettings: StreaksSettings | undefined;
}

function getFacilityPageStreakMessage(
  streaks: Streak[],
  streaksSettings: StreaksSettings | undefined
) {
  const currentActiveStreak = getActiveStreakByDate({
    streaks,
    date: new Date(),
  });

  if (isDefined(currentActiveStreak)) {
    return {
      subTitle: "You have an active Streak at this workplace",
      message: `You have a Streak. You'll receive an extra ${formatPayBoostPercentage(
        currentActiveStreak.attributes.payBoostPercentage
      )} of total pay at the end of the week for shifts worked at this workplace.`,
      label: "Active streak at workplace message",
      hasCurrentActiveStreak: true,
    };
  }

  const streaksSettingsPayBoostEnabled =
    isDefined(streaksSettings) &&
    streaksSettings.attributes.payBoostEnabled &&
    streaksSettings.attributes.payBoostPercentage > 0;

  if (!streaksSettingsPayBoostEnabled) {
    return {
      message: "This workplace is not eligible for Streaks.",
      label: "Not eligible for streaks message",
      hasCurrentActiveStreak: false,
    };
  }

  return {
    subTitle: "Streaks rewards are enabled!",
    message: `Work at least ${
      streaksSettings.attributes.minHoursForActiveStreak
    } verified hours to earn an extra ${formatPayBoostPercentage(
      streaksSettings.attributes.payBoostPercentage
    )} Streak bonus on all shifts worked next week at this workplace.`,
    label: "Streak rewards message",
    hasCurrentActiveStreak: false,
  };
}

export function FacilityStreakInfoCard(props: FacilityStreakInfoCardProps) {
  const { streaks, streaksSettings } = props;
  const theme = useTheme();
  const { message, subTitle, hasCurrentActiveStreak, label } = getFacilityPageStreakMessage(
    streaks,
    streaksSettings
  );

  return (
    <FacilityDetailsCollapse
      isExpandedByDefault
      icon={<LocalFireDepartmentOutlinedIcon sx={{ color: theme.palette.error.main }} />}
      title="Streaks (Beta)"
    >
      <Stack
        aria-roledescription="Facility specific streak messaging"
        aria-label={label}
        spacing={1}
      >
        {subTitle && (
          <Text bold color={hasCurrentActiveStreak ? theme.palette.success.main : undefined}>
            {subTitle}
          </Text>
        )}
        <Text>{message}</Text>
        <Text
          aria-roledescription="Learn more link"
          aria-label="Learn more about streaks link"
          sx={{ fontStyle: "italic" }}
        >
          Learn more about Streaks{" "}
          <ExternalLink to={SUPPORT_LINKS.LEARN_MORE_ABOUT_STREAKS} underline="hover">
            here
          </ExternalLink>
        </Text>
      </Stack>
    </FacilityDetailsCollapse>
  );
}
